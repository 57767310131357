html, body {
    background-color: #f3f3f5 !important; 
}

::-webkit-scrollbar {
    width: 7px !important;   
    height: 7px !important;    
}

::-webkit-scrollbar-thumb {
    background-color: #c1c1c1 !important;
    border-radius: 20px !important;    
}

.class-icon2 {
    color: rgba(0, 0, 0, 0.8) !important;
    font-size: 16px !important;
}

.class-icon2-lg {
    color: rgba(0, 0, 0, 0.8) !important;
    font-size: 20px !important;
}

.e-tbar-btn2 .e-tbar-btn-text {
    font-size: 12px !important;    
}

.e-tbar-btn2 .e-tbar-btn {
  background-color: #e1ecf4 !important;
  border-radius: 10px !important;
  border: 1px solid #7aa7c7 !important;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset !important;
  box-sizing: border-box !important;
  color: #39739d !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 1.15385 !important;
  margin: 0 !important;
  outline: none !important;
  padding: 8px .8em !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
  vertical-align: baseline !important;
  white-space: nowrap !important;
}

.e-tbar-btn2 .e-tbar-btn:hover,
.e-tbar-btn2 .e-tbar-btn:focus {
  background-color: #b3d3ea !important;
  color: #2c5777 !important;
}

.e-tbar-btn2 .e-tbar-btn:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15) !important;
}

.e-tbar-btn2 .e-tbar-btn:active {
  background-color: #a0c7e4 !important;
  box-shadow: none !important;
  color: #2c5777 !important;
}